
.carousel-container {
  max-width: 1600px;
  margin: 0 auto;
}

.carousel-container img {
  max-height: 400px;
  width: auto;
}

.carousel-container .legend {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  text-align: center;
}


.carousel-container .legend p {
  font-size: 16px;
  font-weight: bold;

  margin: 0;
}
