.sobre-paraty-container {
    background: #f7f5f0; /* Fundo claro e sofisticado */
    padding: 50px 20px;
  }
  
  .sobre-paraty-content {
    max-width: 900px;
    margin: 0 auto;
    background: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .sobre-paraty-title {
    font-family: 'Playfair Display', serif;
    font-size: 38px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .sobre-paraty-subtitle {
    font-family: 'Playfair Display', serif;
    font-size: 28px;
    color: #34495e;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .sobre-paraty-paragraph {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 1.8;
    color: #555;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .sobre-paraty-content {
      padding: 20px;
    }
  
    .sobre-paraty-title {
      font-size: 30px;
    }
  
    .sobre-paraty-subtitle {
      font-size: 22px;
    }
  
    .sobre-paraty-paragraph {
      font-size: 16px;
    }
  }
  