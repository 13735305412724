.App {
  width: 100%;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-container {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 46%; /* Define a largura padrão para desktop */
  height: 300px;
  margin-bottom: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 10px;
  background-color: #002776;
  overflow: hidden; /* Garante que o conteúdo do card não ultrapasse suas bordas */
}

/* Adaptação para dispositivos móveis */
@media (max-width: 768px) {
  .card {
    flex-basis: 100%; /* Cards ocupam toda a largura em dispositivos menores */
    height: auto; /* Altura ajustada automaticamente */
    margin-bottom: 10px;
  }
}

.section-title {
  background-color: #ddd;
  text-align: center;
  padding: 10px 0;
  font-size: 24px;
}

.card-content {
  text-align: center;
}

.card-text {
  background-color: #ffb81c;
  max-width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  text-align: right;
  padding: 10px;
}

.card:hover {
  filter: brightness(0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.imagem-responsiva {
  width: 100%;
  height: auto; /* Ajusta a altura automaticamente para manter a proporção */
  max-height: 400px; /* Define uma altura máxima para evitar que fique muito grande */
  object-fit: cover; /* Garante que a imagem preencha o espaço sem distorção */
}

/* Responsividade para .imagem-responsiva */
@media (max-width: 768px) {
  .imagem-responsiva {
    max-height: 300px; /* Reduz a altura máxima em dispositivos menores */
  }
}

.form {
  background-color: #f4f4f4;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center; /* Centraliza o conteúdo do formulário */
  width: 100%; /* Garante que o formulário ocupe toda a largura disponível */
  box-sizing: border-box;
}

h3 {
  margin-bottom: 20px;
  color: #333;
}

input {
  width: 100%; /* Largura total para dispositivos menores */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.button-assinar {
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  background-color: #002776; /* Adiciona um fundo padrão ao botão */
}

.button-assinar:hover {
  background-color: #001f4d; /* Fundo mais escuro no hover */
}
