/* Footer Geral */
footer {
  background-color: #1a1a1a; /* Preto elegante */
  color: #ffffff; /* Texto branco */
  padding: 20px 40px;
  text-align: center;
  font-size: 14px;
}

/* Links no Footer */
footer a {
  color: #d4af37; /* Dourado refinado */
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #ffffff; /* Branco no hover */
}

/* Redes Sociais */
#redes-sociais {
  margin-top: 15px; /* Espaçamento entre texto e ícones */
}

#redes-sociais a {
  margin: 0 15px; /* Espaçamento horizontal entre os ícones */
  color: #d4af37; /* Cor dourada */
  font-size: 18px; /* Tamanho dos ícones */
  transition: color 0.3s ease, transform 0.2s ease;
}

#redes-sociais a:hover {
  color: #ffffff; /* Branco no hover */
  transform: scale(1.2); /* Leve aumento no hover */
}
