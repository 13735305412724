form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow-x: hidden; /* Adiciona overflow-x: hidden para evitar barra de rolagem horizontal */
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
}

.button-container {
  text-align: center;
}

button {
  font-weight: bold;
  padding: 10px;
  color: #002776;
  background: #0095d9;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
    background: #ffb81c;

}

h2 {
  margin-top: 0;
  color: #333;
}

p {
  color: #666;
  margin-bottom: 20px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 10px;
}

h2 {
  margin-top: 20px;
  margin-left: 20px;
}
* {
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}