/* Estilização do controle de busca */
.leaflet-control-geocoder {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 5px;
  font-family: Arial, sans-serif;
}

/* Campo de entrada no geocoder */
.leaflet-control-geocoder input {
  width: 250px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Lista de resultados */
.leaflet-control-geocoder-results {
  margin-top: 5px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-size: 14px;
  max-height: 150px;
  overflow-y: auto;
  width: 250px;
}

/* Cada item de resultado */
.leaflet-control-geocoder-results li {
  padding: 8px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.leaflet-control-geocoder-results li:hover {
  background-color: #f0f8ff;
}

.filtros-container{
text-align: right;
padding-bottom: 10px;;

}