/* HEADER GERAL */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #ffffff; /* Fundo branco puro */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* LOGO */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 60px;
  width: auto;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

/* MENU PRINCIPAL */
.menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 30px;
  position: relative;
}

.menu a, .menu button {
  text-decoration: none;
  color: #1a1a1a; /* Preto clássico para texto normal */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .5px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px; /* Padding fixo para evitar balanço */
  transition: color 0.3s ease, border-bottom-color 0.3s ease; /* Suavizar cor */
  border-bottom: 2px solid transparent; /* Linha invisível no estado normal */
}

.menu a:hover, .menu button:hover,
.menu a.active {
  color: #d4af37; /* Dourado refinado para hover e ativo */
  border-bottom-color: #d4af37; /* Linha dourada no hover */
  
}

/* SUBMENUS */
.submenu-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff; /* Fundo branco para consistência */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 15px 0;
  border-radius: 8px;
  z-index: 1000;
  min-width: 220px; /* Largura mínima */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.submenu-items.show {
  display: block;
  opacity: 1;
  visibility: visible;
}

.submenu-items li {
  margin: 0;
  padding: 10px 15px;
}

.submenu-items li a {
  font-size: 16px;
  color: #1a1a1a; /* Preto clássico para texto */
  font-weight: 400;
  text-decoration: none;
  transition: color 0.3s ease;
}

.submenu-items li a:hover {
  color: #d4af37; /* Dourado refinado no hover dos submenus */
  font-weight: 500;
}

/* RESPONSIVIDADE */
.menu-toggle {
  display: none;
  font-size: 28px;
  background: none;
  border: none;
  color: #1a1a1a; /* Preto clássico */
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    background: #ffffff; /* Fundo branco puro */
    position: absolute;
    top: 70px;
    right: 20px;
    width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    z-index: 1000;
  }

  

  .menu.menu-mobile {
    display: flex;
  }

  .menu li {
    margin: 15px 0;
  }

  .submenu-items {
    position: static;
    box-shadow: none;
    padding: 0;
  }

  .menu-toggle {
    display: block;
  }
}
.logo-title {
  font-size: 23px; /* Tamanho menor para alinhar ao menu */
  font-weight: bold; /* Mantém a ênfase no nome */
  color: #d4af37; /* Dourado refinado */
  text-decoration: none;
  font-family: 'Arial', sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Sombra mais leve */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Suavidade */
}

.logo-title:hover {
  color: #ffffff; /* Branco no hover */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra mais intensa */
}


