/* Estrutura geral */
body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.galeria-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Cabeçalho da galeria */
.galeria-header {
  text-align: center;
  margin-bottom: 40px;
}

.galeria-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #222;
  margin-bottom: 10px;
}

.galeria-header p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Grid de imagens */
.galeria-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.galeria-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.galeria-item img {
  width: 100%;
  height: auto; /* Mantém a proporção */
  object-fit: contain; /* Exibe a imagem inteira */
  transition: transform 0.4s ease;
}

.galeria-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.galeria-item:hover img {
  transform: scale(1.1);
}

/* Overlay (título sobre a imagem) */
.galeria-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px 15px;
  text-align: left;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.galeria-item:hover .galeria-overlay {
  opacity: 1;
}

.galeria-overlay span {
  font-size: 1.2rem;
  font-weight: 500;
}

/* Modal - Exibição full-screen */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: zoom-out;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  object-fit: contain; /* Ensures image fits within container without distortion */

}

.fechar-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  z-index: 1001;
}

.fechar-modal:hover {
  color: #f00;
}

/* Responsividade */
@media (max-width: 768px) {
  .galeria-header h1 {
    font-size: 2rem;
  }

  .galeria-header p {
    font-size: 1rem;
  }

  .galeria-grid {
    gap: 15px;
  }

  .galeria-overlay span {
    font-size: 1rem;
  }
}
/* Estrutura geral */
.galeria-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.galeria-header {
  text-align: center;
  margin-bottom: 40px;
}

.galeria-header h1 {
  font-size: 2.5rem;
  color: #2c3e50;
}

.galeria-header p {
  font-size: 1.1rem;
  color: #7f8c8d;
  margin-top: 10px;
}

/* Categorias */
.galeria-categoria {
  margin-bottom: 50px;
}

.galeria-categoria h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #34495e;
}

.galeria-categoria p {
  font-size: 1rem;
  color: #95a5a6;
  margin-bottom: 20px;
}

/* Grid */
.galeria-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Itens da galeria */
.galeria-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.galeria-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.galeria-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.galeria-item:hover img {
  transform: scale(1.1);
}

.galeria-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.galeria-item:hover .galeria-overlay {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: zoom-out;
}

.modal-content img {
  max-width: 90%;
  max-height: 90%;
}

.fechar-modal {
  position: absolute
}
.galeria-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.galeria-header {
  text-align: center;
  margin-bottom: 40px;
}

.galeria-categoria h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.galeria-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.galeria-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.tema-modal h2 {
  text-align: center;
  margin-bottom: 20px;
}
